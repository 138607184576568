import { Component } from '../../../../../lib/web/components/component';
import { ModalComponent as ModalComponentBase } from '../../../../../lib/web/components/modal.component';
import { LocalStorageService } from '../../../../../lib/web/services/local-storage.service';
import { ModalItemTranslation } from '../../../../../shared/server/model/modal-item';
import { ApiService } from '../../../services/api.service';
import { EjsService } from '../../../services/ejs.service';
import { CarrouselModalComponent } from '../carrousel-modal/carrousel-modal.component';

@Component({
    selector: '.o-modal'
})
export class ModalComponent extends ModalComponentBase<HTMLInputElement> {

    private _modals: Modal[] = null;
    private _modalsShowCount: { [id: string]: number } = {};
    private _pendingTriggers: ('login' | 'new-session')[] = [];

    public constructor(node: HTMLInputElement,
        private _apiService: ApiService,
        private _ejsService: EjsService,
        private _localStorageService: LocalStorageService) {
        super(node);

        this.isDisposable = false;

        this.addCustomWindowEventListener('page-loaded', () => {
            this.loadPending();
        });
        this.addCustomWindowEventListener('login', () => {
            setTimeout(() => {
                this._pendingTriggers.push('login');
                this.loadPending();
            }, 1000);
        });
        this.addCustomWindowEventListener('new-session', () => {
            this._pendingTriggers.push('new-session');
            this.loadPending();
        });

        this.addCustomWindowEventListener('close-carrousel-modal', () => {
            this.close(this.node.querySelector('.u-modal-show'));
        });
    }

    public onInit(): void {
        super.onInit();
    }

    private async loadPending(): Promise<void> {
        if (!this.isLoadingPage) {
            while (this._pendingTriggers?.length) {
                const [first] = this._pendingTriggers;
                this._pendingTriggers = this._pendingTriggers.slice(1);
                await this.loadData(first);
            }
        }
    }

    private async loadData(trigger: 'login' | 'new-session'): Promise<void> {
        this.isLoadingData = true;
        this._modals = await this._apiService.get(`/api/v1/modal/${trigger}`);

        for (const modal of this._modals) {
            const isOncePerDevice: boolean = modal.recurrence === 'once-per-device';
            if (!modal.items) {
                return;
            }
            const isMultiple = modal.items?.length > 1;
            this._modalsShowCount[modal.id] = 0;
            const isShowed: boolean | ModalTime = this.handleIsShowed(modal.id, isOncePerDevice, modal.frequency, modal.maxTimes);
            if (isShowed) {
                continue;
            }
            if (modal.id?.toString().startsWith('pwd-') && location.pathname == '/u/profile/change-password') {
                return;
            }

            if (modal.display) {
                this.node.setAttribute("aria-label", modal.display);
            }

            for (const m of modal.items) {
                const modalElement: HTMLDivElement = this.createModal(m);
                if (!isMultiple) {
                    this.node.appendChild(modalElement);
                    await this.open(modalElement);
                }
                m.hideButtons = true;
            }

            this._modalsShowCount[modal.id]++;
            this._localStorageService.set(`modal-${modal.id}`, isOncePerDevice 
                ? true : { time: Date.now(), times: this._modalsShowCount[modal.id] });
            
            if (isMultiple) {
                modal.isClosable = false;
                await this.createSlider(modal);
            }
        }
        this.isLoadingData = false;
    }

    private handleIsShowed(id: number, isOncePerDevice: boolean, frequency?: number, maxTimes?: number): boolean | ModalTime {
        const modalShowed: ModalTime = this._localStorageService.get(`modal-${id}`);
        if (isOncePerDevice) {
            return !!modalShowed;
        }
        else {
            const time: number = new Date().addHours(frequency).getTime();
            if (modalShowed) {
                const isTimeShowed: boolean = time > new Date(modalShowed.time).getTime();
                if (maxTimes) {
                    this._modalsShowCount[id] = modalShowed.times || 1;
                    return isTimeShowed && modalShowed.times <= maxTimes;
                }
                else {
                    return isTimeShowed;
                }
            }
            return false;
        }
    }

    private onButtonClick(button: HTMLButtonElement, modalElement: Element, url?: string, newTab?: boolean) {
        button.onclick = () => {
            if (url) {
                window.open(url, newTab ? '_blank' : '_self').focus()
            }
            this.close(modalElement);
        };
    }

    private createModal(modal: ModalItem) {
        const element: HTMLDivElement = this.createElement('o-modal-item', this._ejsService.render('organisms/modal/modal-item', { modal }));
        const closeButton: HTMLButtonElement = element.querySelector('.u-modal-close-button');
        if (closeButton) {
            closeButton.onclick = () => this.close(element);
        }
        const primaryButton: HTMLButtonElement = element.querySelector('.u-button--primary');
        if (primaryButton) {
            this.onButtonClick(primaryButton, element, modal.primaryButtonUrl, modal.primaryButtonOpenInNewWindow);
        }
        const secondaryButton: HTMLButtonElement = element.querySelector('.u-button--secondary');
        if (secondaryButton) {
            this.onButtonClick(secondaryButton, element, modal.secondaryButtonUrl, modal.secondaryButtonOpenInNewWindow);
        }
        return element;
    }

    private async createSlider(data: Modal) {
        if (data.items.length) {
            const slider: HTMLDivElement = this.createElement(
                'o-carrousel-modal',
                this._ejsService.render('organisms/carrousel-modal/carrousel-modal', { data: data.items })
            );
            this.node.appendChild(slider);

            const carrousel = new CarrouselModalComponent(slider);
            const carrouselContent = carrousel.node.querySelectorAll('.o-modal-item__content');
            carrouselContent.forEach(el => (el as HTMLElement).classList.add('o-modal-item__slide'));
            const closeButton: HTMLButtonElement = carrousel.node.querySelector('.u-modal-close-button');
            if (closeButton) {
                closeButton.onclick = () => this.close(slider);
            }

            carrousel.onInit();
            await this.open(slider);
        }
    }

    private createElement(className: string, value?: string) {
        const element = document.createElement('div');
        element.classList.add(className);
        if (value) {
            element.innerHTML = value;
        }
        return element;
    }
}

interface Modal {
    id: number,
    recurrence: 'once-per-device' | 'frequency',
    frequency?: number,
    maxTimes?: number,
    display?: string;
    isClosable?: boolean;
    items?: ModalItem[];
}

interface ModalTime { time: Date, times: number }
interface ModalItem extends ModalItemTranslation {
    id: number;
    icon?: string;
    hideButtons?: boolean;
}